import React, { useEffect, useState } from 'react';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItem from '@material-ui/core/ListItem';
import { Badge, Skeleton } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { connect } from 'react-redux';
import { config } from '../../settings';
import { renderTimestamp } from '../../utils/formatting';
import { NotificationsStore } from '../../stores';
import { useAlertStatus } from '../../utils/useSettings';
import { updatePersistedState } from '../../utils/usePersistedState';
import { language, messages } from '../../utils/localeUtils';

// const notifications = [{
//   id: 1,
//   title: 'Test',
//   message: 'Welcome to Boxpressd!!',
//   image_url: '',
//   type: '',
//   unread: true,
// }, {
//   id: 2,
//   title: 'Test',
//   message: 'Welcome to Boxpressd!!',
//   image_url: '',
//   type: '',
//   unread: false,
// }];

export const notificationLink = (notification) => {
  console.log(notification.path);
  // TODO Base the link off of the attached object? Or include a type field in the notification?
  return notification.path;
};

export const updateReadReport = (notification) => {
  if (!notification.read) {
    const read = true;
    NotificationsStore.update((s) => {
      for (let i = 0; i < s.notifications.length; i++) {
        if (s.notifications[i].id === notification.id) {
          s.notifications[i] = {
            ...notification,
            read,
          };
          break;
        }
      }
      s.notificationCount = s.notificationCount > 0 ? s.notificationCount - 1 : 0;
      // Just take the values from above and update the persisted state - may want to do away with the handling above
      // and just find a way to get below working by itself.
      updatePersistedState('alert-status', {
        // FIXME Why is it that at random times this is an integer??? Should we clone the array above rather than using it straight from the store?
        notifications: Array.isArray(s.notifications) ? s.notifications : [],
        notificationCount: s.notificationCount,
      });
    });

    axios.put(`${config.apiEndPoint}/users/notifications/${notification.id}`, {
      ...notification,
      read,
    });
  }
};

function Alerts(props) {
  const [alertStatus, setAlertStatus] = useAlertStatus({
    notifications: [],
    notificationCount: 0,
  });
  const { notifications } = alertStatus;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const { auth } = props;
    const { user } = auth;
    if (user && user.id) {
      axios.get(`${config.apiEndPoint}/users/${user.id}/notifications`).then((results) => {
        console.log('Notifications:');
        console.log(results.data);
        setLoading(false);
        let notificationCount = 0;
        for (const notification of results.data) {
          if (!notification.read) {
            notificationCount += 1;
          }
        }
        setAlertStatus({
          notifications: results.data,
          notificationCount,
        });
      }).catch((err) => {
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    console.log('Notifications updated:');
    console.log(notifications);
  }, [notifications]);

  // TODO Include sections for date stamps
  return (
    <div>
      <div style={{ margin: 16 }}>
        <h3>{messages[language]?.notifications || 'Notifications'}</h3>
        {/* TODO Dots menu - allow marking all as read, and possibly deleting all */}
      </div>
      {loading && (
        <div style={{ margin: 10, display: 'flex', flexDirection: 'row' }}>
          <Skeleton variant="circular" height={70} width={70} style={{ marginRight: 10 }} />
          <Skeleton height={50} style={{ flex: 1 }} />
        </div>
      )}
      {!loading && (!notifications || notifications.length === 0) && (<div style={{ margin: 10, textAlign: 'center' }}>All caught up!</div>)}
      <List>
        {!loading && Array.isArray(notifications) && notifications.map((notification) => (
          <Link to={() => notificationLink(notification)}>
            <ListItem button key={`notification-${notification.id}`} onClick={() => updateReadReport(notification)}>
              {/* FIXME These need to be dynamic - they won't always be user avatars - see BXPR-589 */}
              <Avatar
                src={notification.image_url && notification.image_url.replace('/users/profile/', '/100x100/users/profile/')}
                style={{ height: 64, width: 64, marginRight: 22 }}
              />
              <ListItemText
                primary={<span style={{ fontSize: '1.1rem', fontWeight: notification.read ? 400 : 700 }}>{notification.content}</span>}
                secondary={<span style={{ color: notification.read ? 'inherit' : '#d6c290', fontWeight: notification.read ? 400 : 700 }}>{renderTimestamp(notification.timestamp)}</span>}
              />
              {!notification.read && (
                <ListItemSecondaryAction style={{ right: 30 }}>
                  <Badge badgeContent=" " color="primary" variant="dot" />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(Alerts);

import wpPageSaga from '../WpPage/sagas';
import wpPostListSaga from '../WpPostList/sagas';
import wpPostDetailSaga from '../WpPostDetail/sagas';
import wpCategorySaga from '../WpCategory/sagas';
import wpCommentsSaga from '../WpPostComments/sagas';
import feedSaga from '../FeedList/sagas';
import humidorSaga from '../Humidor/sagas';
import authSaga from '../Auth/sagas';
import savedItemsSaga from '../SavedItems/sagas';
import exploreCigarsSaga from '../Explorer/sagas';
import filterAllProductsSaga from '../ShopAll/sagas';
import filterProductDealsSaga from '../ShopResults/sagas';
import loadChatMessagesSaga from '../VirtualLounge/sagas';
import smokeSessionsSaga from '../SessionEditor/sagas';
import venueCheckinSaga from '../CheckinEditor/sagas';
import productReviewSaga from '../ProductReviewEditor/sagas';
import searchSaga from '../Search/sagas';

export default [
  ...feedSaga,
  ...humidorSaga,
  ...wpPageSaga,
  ...wpPostListSaga,
  ...wpPostDetailSaga,
  ...wpCategorySaga,
  ...wpCommentsSaga,
  ...authSaga,
  ...savedItemsSaga,
  ...filterAllProductsSaga,
  ...filterProductDealsSaga,
  ...exploreCigarsSaga,
  ...loadChatMessagesSaga,
  ...smokeSessionsSaga,
  ...venueCheckinSaga,
  ...productReviewSaga,
  ...searchSaga,
];

import { combineReducers } from 'redux-immutable';
import feedReducer from '../../pages/FeedList/reducer';
import humidorReducer from '../../pages/Humidor/reducer';
import wpPageReducer from '../../pages/WpPage/reducer';
import wpPostListReducer from '../../pages/WpPostList/reducer';
import wpPostEntityReducer from '../../pages/WpPostDetail/reducer';
import wpCategoryReducer from '../../pages/WpCategory/reducer';
import wpCommentsReducer from '../../pages/WpPostComments/reducer';
import exploreCigarsReducer from '../../pages/Explorer/reducer';
import filterProductsAllReducer from '../../pages/ShopAll/reducer';
import filterProductsDealsReducer from '../ShopResults/reducer';
import smokeSessionReducer from '../../pages/SessionEditor/reducer';
import checkinsReducer from '../../pages/CheckinEditor/reducer';
import productReviewReducer from '../../pages/ProductReviewEditor/reducer';
import { SWITCH_LIST_TYPE, TYPE_LIST } from './actions';

export function listTypeReducer(state = TYPE_LIST, action) {
  if (action.type === SWITCH_LIST_TYPE) {
    return action.listType;
  }
  return state;
}

export default combineReducers({
  feed: feedReducer,
  humidors: humidorReducer,
  smokeSessions: smokeSessionReducer,
  checkins: checkinsReducer,
  productReviews: productReviewReducer,
  wpPage: wpPageReducer,
  wpPost: combineReducers({
    entities: wpPostEntityReducer,
    list: wpPostListReducer,
    comments: wpCommentsReducer,
  }),
  wpCategory: wpCategoryReducer,
  cigars: combineReducers({
    explore: exploreCigarsReducer,
  }),
  products: combineReducers({
    all: filterProductsAllReducer,
    deals: filterProductsDealsReducer,
  }),
  listType: listTypeReducer,
});

export const SWITCH_LIST_TYPE = 'SWITCH_LIST_TYPE';
export const SEARCH_POST = 'SEARCH_POST';

export const TYPE_DETAIL = 'TYPE_DETAIL';
export const TYPE_GRID = 'TYPE_GRID';
export const TYPE_LIST = 'TYPE_LIST';
export const CARD_ARTICLE = 'posts';
export const CARD_VIDEO = 'video';

export function switchListType(listType) {
  return {
    type: SWITCH_LIST_TYPE,
    listType,
  };
}

export function searchPost(term) {
  return {
    type: SEARCH_POST,
    term,
  };
}

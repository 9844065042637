import { fromJS } from 'immutable';
import Cookies from 'js-cookie';
import branch from 'branch-sdk';
import { AUTH_USER, SIGN_OUT_USER, AUTH_ERROR, UPDATE_USER } from './actions';
import firebaseApp from '../../../config/firebase.config';
import { UserStore } from '../../stores';
import { setUserIdentities } from '../../utils/userIdentities';
import { Transducer } from '../../utils/transducer';

const user = Transducer.getPreference('user');
if (user) {
  if (window.fcWidget) {
    window.fcWidget.user.setProperties({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone,
    });
  }
  if (window.pendo) {
    window.pendo.initialize({
      visitor: {
        id: user.id,
        email: user.email,
        full_name: user.full_name || (`${user.first_name} ${user.last_name}`),
      },
    });
  }
  if (window.tidioChatApi) {
    window.tidioChatApi.setVisitorData({
      distinct_id: user.id,
      name: user.full_name,
      email: user.email,
      phone: user.phone,
    });
  }
  try {
    window.parent.postMessage({
      type: 'user_updated',
      user,
    }, '*');
  } catch (e) {}
}
const authToken = Transducer.getPreference('boxpressd_token_id');
const authState = fromJS({
  user: user || null,
  authenticated: !!authToken,
  token_id: authToken,
  error: {
    show: false,
    title: '',
    message: '',
  },
});

export default function authReducer(state = authState, action) {
  switch (action.type) {
    case AUTH_USER: {
      const currentUser = action.user;
      // FIXME Start using useUserState across the entire app
      UserStore.update((s) => { s.user = currentUser });

      console.log('Storing logged in user to local storage');
      Transducer.setPreference('authenticated', true);
      Transducer.setPreference('user', currentUser);
      Transducer.setPreference('boxpressd_token_id', action.token_id);
      Transducer.setPreference('firebase_id', firebaseApp.auth().currentUser.uid);
      console.log('Stored logged in user to local storage');

      // FIXME Only use one or the other, not both cookie and ls - only benefit to a cookie might be to force user to reauthenticate after 30 days or something
      // Cookies.set('tokenId', action.token_id);
      // Cookies.set('tokenId', action.token_id, { expires: 3 });

      if (typeof action.callback === 'function') {
        console.log('Running callback for success');
        action.callback(action.token_id);
      }

      setUserIdentities();

      return state
        .set('user', currentUser)
        .set('authenticated', true)
        .set('token_id', action.token_id)
        .set('error', {
          show: false,
          title: '',
          message: '',
        });
    }
    case UPDATE_USER: {
      const currentUser = action.user;
      console.log('Updated user in Redux:');
      console.log(currentUser);
      Transducer.setPreference('user', currentUser);
      UserStore.update((s) => { s.user = currentUser });

      // FIXME Are these necessary?
      // Sentry.setUser({
      //   id: currentUser.id,
      //   email: currentUser.email,
      // });
      //
      // if (window.analytics) {
      //   window.analytics.identify(currentUser.id, {
      //     email: currentUser.email,
      //     name: currentUser.full_name,
      //   });
      // }
      //
      // if (typeof branch.setIdentity === 'function') {
      //   branch.setIdentity(currentUser.id);
      // }

      setUserIdentities();

      return state
        .set('user', currentUser)
        .set('error', {
          show: false,
          title: '',
          message: '',
        });
    }
    case AUTH_ERROR: {
      if (typeof action.callback === 'function') {
        console.log('Running callback for error');
        action.callback(action.token_id);
      }

      return state.set('error', action.error);
    }
    case SIGN_OUT_USER: {
      Transducer.clearPreferences();
      Cookies.set('tokenId', null);

      if (typeof branch.logout === 'function') {
        branch.logout();
      }

      if (window.OneSignalPlugin) {
        window.OneSignalPlugin.removeExternalUserId();
        window.OneSignalPlugin.logoutEmail();
      }

      Transducer.postMessage('on_log_out');

      if (typeof action.callback === 'function') {
        console.log('Running callback for sign out');
        action.callback();
      }

      // FIXME Set all to defaults?
      return state;
    }
    default: {
      return state;
    }
  }
}

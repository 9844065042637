import firebaseApp from '../../../config/firebase.config';
import { takeLatest } from 'redux-saga/effects';
import {
  signOutUser,
  VERIFY_AUTH,
} from './actions';

// FIXME This isn't currently being used - do we actually need it as an action? It's in App.jsx
export function verifyAuth() {
  return (dispatch) => {
    firebaseApp.auth().onAuthStateChanged((user) => {
      if (user) {
        console.log('User is logged in!');
        // FIXME Only case where user checks "Keep me logged in" -- this will continue to keep their auth active with Firebase
        firebaseApp.auth().currentUser.getIdToken()
          .then((tokenId) => {
            console.log('Got new token ID:');
            console.log(tokenId);
            // FIXME This is wrong - the user object here isn't the one we want to store - maybe updateAuth() to update token ID?
            // dispatch(authUser(currentUser));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        console.log('User is logged out!');
        dispatch(signOutUser());
      }
    });
  };
}

export function* verifyAuthWatcher() {
  yield takeLatest(VERIFY_AUTH, verifyAuth);
}

export default [
  verifyAuthWatcher,
];
